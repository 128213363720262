import axios from "axios";
import Vue from 'vue'
import store from '../store'

let config = {
    baseURL: `${process.env.VUE_APP_URL_API}:${process.env.VUE_APP_PORT}`,
}
  
const _axios = axios.create(config)

_axios.interceptors.request.use(async function (config) {  
    let token = await store.dispatch("checkToken", config);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})


// if(process.env.VUE_APP_MODE == "web") {
//     axios.defaults.proxy = {
//         host: process.env.VUE_PROXYHOST,
//         port: process.env.VUE_PROXYPORT
//       };
  
//       session.defaultSession.setProxy({
//         proxyRules: `http://${process.env.VUE_PROXYHOST}:${process.env.VUE_PROXYPORT}`,
//         proxyBypassRules: "<local>, localhost, 10.0.4.25"
//       }, () => {
//         console.log("Proxy configurado para el tráfico específico.");
//       });
// }

export default {
    install () {
        Vue.prototype.$axios = _axios
    }
};