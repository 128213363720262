<template>
  <div class="login-view">
    <div>
      <v-card
        class="px-5 py-8"
        color="background"
        width="480px"
        style="border-radius: 15px"
      >
        <h2 class="mb-4">Selecciona un estudio</h2>

        <div>
          <v-select
            :items="items"
            label="Selecciona"
            item-text="name"
            item-value="number"
            v-model="select"
          ></v-select>
        </div>
        <h2 class="mb-4">Selecciona un tipo de usuario</h2>

        <div>
          <v-select
            :items="slaves"
            label="Selecciona"
            item-text="name"
            item-value="type"
            v-model="slave"
          ></v-select>
        </div>
        <v-btn
          block
          color="primary"
          :disabled="select == null"
          @click="submitStudio"
        >
          Entrar
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      valid: false,
      items: [],
      select: null,
      slaves: [
        {
          name: "Locutor",
          type: "",
        },
        { name: "Creativo", type: "plus" },
      ],
      slave: "",
    };
  },
  computed: {
    ...mapState(["arrStudios", "studio", "idStudio", "role"]),
    disableBtn() {
      return this.select == null
    }
  },
  methods: {
    ...mapActions(["getStudios"]),
    ...mapMutations(["setStudio", "setTypeSlave"]),
    saveConfig() {
      window.ipcRenderer.saveConfig({
        studio: this.select,
        typeSlave: this.slave
      });
    },
    async submitStudio() {
      await this.setStudio(this.select);
      await this.setTypeSlave(this.slave);
      window.ipcRenderer.saveConfig({
        typeSlave: this.slave,
        studio: this.select,
        savepath: null,
      });
      this.saveConfig()
      this.$globalData.socket.connect();
      this.$router.push("/slave")
    },
    async fillStudios() {
        this.items = [];
        const studios = await this.getStudios()
        studios.data.forEach((studio) => {
          if (studio.active == true) {
            const studioNumber = studio.name.charAt(studio.name.length - 1);
            studio.number = Number(studioNumber);
            this.items.push(studio);
          }
        });
      
    },
  },
  async created() {
    this.fillStudios();
    if(this.studio !== null) {
      this.$router.push("/slave")
    }
  },
};
</script>

<style scoped>
.login-view {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>