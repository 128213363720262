<template>
    <v-sheet class="sessions">
        <Header title="Sesión de grabación" />
        <v-subheader class="sub-header">
            <v-tabs color="admiraGreen" v-model="tab" align-with-title>
                <v-tabs-slider color=""></v-tabs-slider>

                <v-tab class="ml-0" v-for="item in sessionsTypes" :key="item.id">
                    {{ item.name }}
                </v-tab>
            </v-tabs>
            <v-spacer />
            <!-- <div class="d-flex">
                <v-btn class="sub-header__btn" elevation="0" outlined color="admiraGreen"> <v-icon dark>
                        mdi-cloud-print
                    </v-icon></v-btn>
            </div> -->
        </v-subheader>
        <v-tabs-items v-model="tab">
            <v-tab-item :transition="false" :value="0">
                <sessions-filter :find-vitaminated="findSpots" :search-vit="searchPageSessions"
                    :clear-search-field="clearFindSpots" :medias="medias" />
                <v-card flat>
                    <div class="table-container">
                        <CustomDataTable :open-right-menu="showRecording" :headers="headerSpots" :data="spots"
                            :loadingData="myloadingvariable" :showSelect="true" :selected="selected" :item-key="'_id'"
                            :searchBy="false" @selectedchange="(value) => selected = value" :statusName="true"
                            :retrieveItems="onFetchSessionSpots" :searchPage="searchPageSessions" />
                    </div>
                    <div class="d-flex align-center">
                        <v-btn class="justify-center ml-4 admiraGreen" rounded depressed small dark width="120"
                            @click="handleEncodeClick">Codificar
                        </v-btn>
                        <v-btn class="justify-center ml-4 admiraGreen" rounded depressed small dark width="180"
                            @click="dialogChangeStatusEncoded = true" :disabled="spots.length == 0">Quitar
                            codificados
                        </v-btn>
                        <v-btn class="justify-center ml-4 admiraGreen" rounded depressed small dark width="200"
                            @click="onChangeSomeStatusEncoded" :disabled="selected.length == 0">Quitar
                            seleccionados
                        </v-btn>
                        <v-spacer></v-spacer>
                        <pagination :total-pages="totalPagesFiles" :retrieve="onFetchSessionSpots"
                            :search-page="searchPageSessions" />
                    </div>
                </v-card>
            </v-tab-item>
            <v-tab-item :transition="false" :value="1">
                <megafonia-filter :find-vitaminated="findMega" :search-vit="searchPageMega"
                    :clear-search-field="clearFindMega" />
                <v-card flat>
                    <div class="table-container">
                        <CustomDataTable :transDuartion="true" :transDate="true" :open-right-menu="showRecording" :headers="headerMega"
                            :data="megas" :loadingData="myloadingvariable" :selected="selected2" :item-key="'_id'"
                            :searchBy="false" @selectedchange="(value) => selected2 = value" :statusName="true"
                            :retrieveItems="onFetchSessionMega" :searchPage="searchPageMega" />
                    </div>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <pagination :total-pages="totalPagesFiles" :retrieve="onFetchSessionMega"
                            :search-page="searchPageMega" />
                    </div>
                </v-card>
            </v-tab-item>
            <v-tab-item :transition="false" :value="2">
                <radio-filter :find-vitaminated="findRadio" :search-vit="searchPageRadio"
                    :clear-search-field="clearFindRadio" />
                <v-card flat>
                    <div class="table-container">
                        <CustomDataTable :transDuartion="true" :transDate="true" :open-right-menu="showRecording" :headers="headerRadio"
                            :data="radios" :loadingData="myloadingvariable" :selected="selected3" :item-key="'_id'"
                            :searchBy="false" @selectedchange="(value) => selected3 = value" :statusName="true"
                            :retrieveItems="onFetchSessionRadio" :searchPage="searchPageRadio" />
                    </div>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <pagination :total-pages="totalPagesFiles" :retrieve="onFetchSessionRadio"
                            :search-page="searchPageRadio" />
                    </div>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            >
                {{ counter }}/{{ totalSpots.length }}
            </v-progress-circular>
        </v-overlay>
        <v-dialog
          v-model="dialog"
          width="500"
        >
        <v-card>
          <v-card-title class="text-h5">
              Aviso
          </v-card-title>
          <v-card-text>Codificado de cuñas completado</v-card-text>
          <v-card-text v-if="failedEncoded.length > 0">Error al codificar la(s) cuña(s): {{ failedEncoded }}</v-card-text>
          <v-card-text v-if="fakeSpots.length > 0">La(s) cuña(s) no existe(n) en plataforma: {{ fakeSpots }}</v-card-text>


          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded 
                depressed 
                color="primary" 
                small 
                dark 
                width="120" @click="dialog = false">
                  Aceptar
              </v-btn>
          </v-card-actions>
      </v-card>
        </v-dialog>
        <v-dialog v-model="dialogChangeStatusEncoded" persistent max-width="320">
          <v-card>
              <v-card-title class="text-h5">
                  Aviso
              </v-card-title>
              <v-card-text>¿Desea quitar todos los codificados?</v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn rounded 
                    depressed 
                    color="admiraGreen" 
                    small 
                    dark 
                    width="120" @click="dialogChangeStatusEncoded = false">
                      Cancelar
                  </v-btn>
                  <v-btn rounded 
                    depressed 
                    color="red" 
                    small 
                    dark 
                    width="150" @click="onChangeStatusEncoded">
                      Aceptar
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        Header: defineAsyncComponent(() => import("@/components/Header")),
        CustomDataTable: defineAsyncComponent(() => import("@/components/tables/customDataTable")),
        sessionsFilter: defineAsyncComponent(() => import("@/components/filter/sessionsFilter")),
        megafoniaFilter: defineAsyncComponent(() => import("@/components/filter/megafoniaFilter")),
        radioFilter: defineAsyncComponent(() => import("@/components/filter/radioFilter")),
        Pagination: defineAsyncComponent(() => import("@/components/Pagination"))
    },
    data() {
        return {
            tab: this.tabSelected,
            sessionsTypes: [
                { name: "Sesión", id: 0 },
                { name: "Megafonías", id: 1 },
                { name: "Radio", id: 2 }
            ],
            spots: [],
            headerSpots: [
                { text: "", value: "data-table-select" },
                { text: "Número", value: "code" },
                { text: "Título", value: "name" },
                { text: "Medio en producción", value: "corporateMediaId.name" },
                { text: "Idioma", value: "language" },
                { text: "Estado en sesión", value: "status" },
            ],
            headerMega: [
                { text: "Número", value: "code" },
                { text: "Título", value: "name" },
                { text: "Idioma", value: "language" },
                { text: "Fecha Grabación", value: "recordingDate" },
                { text: "Duración", value: "duration" },
                { text: "Estado en sesión", value: "status" },
            ],
            headerRadio: [
                { text: "Código", value: "spotCode" },
                { text: "Referencia", value: "code" },
                { text: "Título", value: "name" },
                { text: "Fecha Grabación", value: "recordingDate" },
                { text: "Duración", value: "duration" },
                { text: "Estado en sesión", value: "status" },
            ],
            myloadingvariable: false,
            selected: [],
            selected2: [],
            selected3: [],
            megas: [],
            radios: [],
            medias: [],
            sendcodes: [],
            overlay: false,
            counter: 0,
            dialog: false,
            failedEncoded: [],
            dialogChangeStatusEncoded: false,
            fakeSpots: [],
            totalSpots: [],
        }
    },
    watch: {
        tab() {
            switch (this.tab) {
                case 0:
                    this.onFetchSessionSpots()
                    break;
                case 1:
                    this.onFetchSessionMega()
                    break;
                case 2:
                    this.onFetchSessionRadio()
                    break;

                default:
                    break;
            }
        }
    },
    computed: {
        ...mapState([
            "path",
            "tabSelected"
        ]),
        ...mapState("spots", [
            "searchPageSessions",
            "searchPageMega",
            "searchPageRadio",
            "totalPagesFiles",
        ])
    },
    methods: {
        ...mapActions("spots", ["fetchSessionSpots", "fetchCoporateMedias", "fetchSessionMega", "fetchSessionRadio", "changeStatusEncoded", "changeStatusEncodedSelected"]),
        async findSpots() {
            this.searchPageSessions.page = 1;
            this.onFetchSessionSpots();
        },
        handleEncodeClick() {
            if(this.selected.length == 0) {
                this.selected = this.spots
            }
            this.counter = 0;
            this.failedEncoded = []
            this.sendcodes = []
            this.fakeSpots = []
            this.getData2();
            this.overlay = true
            if(this.sendcodes.length == 0) {
                this.overlay = false
                this.onFetchSessionSpots();
                this.dialog = true
                this.selected = []
             return  
            }
            window.ipcRenderer.send('batchrender', this.sendcodes);
        },
        async clearFindSpots() {
            this.searchPageSessions.search = '';
            this.searchPageSessions.page = 1;
            this.onFetchSessionSpots();
        },
        async findMega() {
            this.searchPageMega.page = 1;
            this.onFetchSessionMega();
        },
        async clearFindMega() {
            this.searchPageMega.search = '';
            this.searchPageMega.page = 1;
            this.onFetchSessionMega();
        },
        async findRadio() {
            this.searchPageRadio.page = 1;
            this.onFetchSessionRadio();
        },
        async clearFindRadio() {
            this.searchPageRadio.search = '';
            this.searchPageRadio.page = 1;
            this.onFetchSessionRadio();
        },
        async onFetchSessionSpots(change) {
            this.myloadingvariable = true
            if (change == "changeRows") {
                this.searchPageSessions.page = 1;
            }
            const response = await this.fetchSessionSpots([
                this.searchPageSessions.itemsPerPage,
                this.searchPageSessions.page,
                this.searchPageSessions,
                this.searchPageSessions.sortBy,
                this.searchPageSessions.sortDesc
            ])
            this.spots = response.data.data
            // this.spots.forEach(spot => {
            //     for (let index = 0; index < this.medias.length; index++) {
            //         const media = this.medias[index];
            //         if (spot.corporateMediaId == media._id) {
            //             spot.mediaName = media.name
            //         }
            //     }
            // })
            this.myloadingvariable = false
        },
        async onFetchSessionMega(change) {
            this.myloadingvariable = true
            if (change == "changeRows") {
                this.searchPageMega.page = 1;
            }
            const megas = await this.fetchSessionMega([
                this.searchPageMega.itemsPerPage,
                this.searchPageMega.page,
                this.searchPageMega,
                this.searchPageMega.sortBy,
                this.searchPageMega.sortDesc
            ])
            this.megas = megas.data.data
            this.myloadingvariable = false
        },
        async onFetchSessionRadio(change) {
            this.myloadingvariable = true
            if (change == "changeRows") {
                this.searchPageRadio.page = 1;
            }
            const radios = await this.fetchSessionRadio([
                this.searchPageRadio.itemsPerPage,
                this.searchPageRadio.page,
                this.searchPageRadio,
                this.searchPageRadio.sortBy,
                this.searchPageRadio.sortDesc
            ])
            this.radios = radios.data.data
            this.myloadingvariable = false
        },
        async onChangeStatusEncoded() {
            this.dialogChangeStatusEncoded = false
            if(this.selected.length == 0) {
                this.selected = this.spots
            }
            await this.deleteTempAudios(this.getData())
            await this.changeStatusEncoded()
            await this.onFetchSessionSpots()
            this.selected = []
        },
        async onChangeSomeStatusEncoded() {
            await this.deleteTempAudios(this.getData())
            await this.changeStatusEncodedSelected(this.getIds())
            await this.onFetchSessionSpots()
            this.selected = []
        },
        async deleteTempAudios(payload) {
            await window.ipcRenderer.send("batch-destroy-audio", payload)
        },
        getData() {
            const codes = []

            this.selected.forEach(session => {
                codes.push({
                    code: session.code,
                    criLang: session.criLang,
                    spotCode: session.spotCode,
                    status: session.status
                })
            })
            return codes
        },
        getData2() {
            this.totalSpots = []
            this.selected.forEach(session => {
                this.totalSpots.push(session)
                if(session?.idAdmira != undefined) {
                    this.sendcodes.push({
                            code: session.code,
                            criLang: session.criLang,
                            mediaType: session.corporateMediaId.name,
                            text: session.text,
                            status: session.status,
                            spotCode : session.spotCode,
                            centerDestination: session.centerDestination,
                        })
                } else {
                    this.fakeSpots.push(
                        session.code
                    )
                }
            })
            return this.sendcodes
        },
        getIds() {
            let ids = []
            this.selected.forEach(session => {
                ids.push(session._id)
            })
            return ids
        },
        showRecording(recording) {

            if(recording.status == "empty" && this.tab == 0) return

            this.$store.state.tabSelected =  this.tab 

                this.$store.state.actualSpot = this.getData3(recording);
                this.$store.dispatch('accesCitations', { acces: false, recording: this.getData3(recording) })
                this.$globalData.socket.emit('getAllowCitation', { acces: false, recording: this.getData3(recording) })
                this.$store.state.path = "sessions"
                this.$store.commit("setActivity", null)
                this.$store.commit("setSpeakerGroup", null)
                this.$store.commit("setCitation", null)
                this.$router.push({
                    name: "showRecording", params: {sessions: true}
                });
                this.$globalData.socket.emit('getAllowCitation', { acces: false, recording: this.getData3(recording) })

        },
        getData3(payload) {
            const recording = this.isGhostSpot(payload)
            if (this.tab == 0) {
                return {
                    mediaType: recording.corporateMediaId.name,
                    spotCode: recording.code,
                    taskId: null,
                    mediaId: null,
                    texts: {
                        criteria: {
                            id: recording.criLang,
                            name: recording.language
                        },
                        text: recording.text
                    },
                    recordingStatus: recording.status,
                    name: recording.name,
                    session: true,
                    centerDestination: recording.centerDestination,
                    realSpotCode: recording.spotCode,
                    idAdmira: recording.idAdmira,
                    _id: recording._id,
                }
            }
            return {
                mediaType: recording.corporateMediaId.name,
                spotCode: recording.code,
                taskId: null,
                mediaId: null,
                texts: {
                    criteria: {
                        id: recording.criLang,
                        name: recording.language
                    },
                    text: recording.text
                },
                recordingStatus: recording.status,
                name: recording.name,
                centerDestination: recording.centerDestination,
                realSpotCode: recording.spotCode,
                languageName: recording.language,
                session: true,
                idAdmira: recording.idAdmira,
                _id: recording._id,
            }
        },
        isGhostSpot(recording) {
            if(recording?.idAdmira == undefined) {
                recording.text = ""
                return recording
            }
            return recording
        }
    },
    async created() {
        const medias = await this.fetchCoporateMedias()
        this.medias = medias.data.data
        if(this.$route.params.from == "showRecording"){
            this.tab = this.tabSelected
        }
        window.ipcRenderer.receive('batchrender-completed', (data) => {
                this.counter++
                if(data.encoded == false) {
                    if(data.criLang == undefined) {
                        this.fakeSpots.push(data.code)
                    }
                    else {
                        this.failedEncoded.push(data.code)
                    }
                }
                if(this.counter == this.sendcodes.length) {
                    this.overlay = false
                    this.onFetchSessionSpots();
                    this.dialog = true
                    this.selected = []
                }
            });
    }

}
</script>
<style scoped>
.sub-header {
    border: 1px solid gainsboro;
    border-left: 0;
    border-right: 0;
    height: 51px;
}

.sub-header__btn {
    border-radius: 8px;
}

.table-container {
    height: calc(100vh - 400px);
    overflow: auto;
    margin: 24px 16px 0;
}</style>